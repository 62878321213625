//! dake.dko.js
//! author : Raphael Cabrera

var today = new Date();

showTwoDigits = function(i) {
  return ("0" + i).slice(-2);
};

Date.prototype.monthName = function() {
  // #TODO SET TRANSLATION DATES

  // get month names set by application or use default
  var monthNames = window.monthNames || [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  var date = new Date(this.valueOf());

  return monthNames[date.getMonth()];
};

Date.prototype.weekDay = function() {
  // #TODO SET TRANSLATION DATES

  var weekDays = window.weekDays || [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  var date = new Date(this.valueOf());

  return weekDays[date.getDay()];
};

Date.prototype.addSeconds = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setSeconds(date.getSeconds() + number);
  return date;
};

Date.prototype.addMinutes = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setMinutes(date.getMinutes() + number);
  return date;
};

Date.prototype.addHours = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setHours(date.getHours() + number);
  return date;
};

Date.prototype.addDays = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + number);
  return date;
};

Date.prototype.addMonths = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setMonth(date.getMonth() + number);
  return date;
};

Date.prototype.addYears = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setFullYear(date.getFullYear() + number);
  return date;
};

// DATE SUBTRACTION

Date.prototype.subHours = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setHours(date.getHours() - number);
  return date;
};

Date.prototype.subDays = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - number);
  return date;
};

Date.prototype.subMonths = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setMonth(date.getMonth() - number);
  return date;
};

Date.prototype.subYears = function(number) {
  var num = parseInt(number);
  var date = new Date(this.valueOf());
  date.setFullYear(date.getFullYear() - number);
  return date;
};

Date.prototype.getLastSunday = function() {
  var sunday = new Date(this.getTime());
  sunday.setDate(sunday.getDate() - sunday.getDay());
  sunday.setHours(0, 0, 0, 0);
  return sunday;
};

Date.prototype.getMonthStart = function() {
  var month = new Date();
  month.setDate(1);

  return month;
};

Date.prototype.getLastYear = function() {
  var lastYear = new Date();
  lastYear.setFullYear(lastYear.getFullYear() - 1);

  return lastYear.getFullYear();
};

// Date.prototype.getMonthYear = function(){

// 	month = showTwoDigits(this.getMonth() + 1);
//     year = this.getFullYear().toString().slice(-2);

//     return month + "/" + year;

// }

// Date.prototype.getDateString = function(){

//     day = showTwoDigits(this.getDate());
//     month = showTwoDigits(this.getMonth() + 1);
//     year = this.getFullYear();

//     var formatted = day + "/" + month + "/" + year;

//     return formatted;

// }

Date.prototype.getTimeString = function() {
  var hours = showTwoDigits(this.getHours());
  var minutes = showTwoDigits(this.getMinutes());

  return hours + ":" + minutes;
};

Date.prototype.getDateString = function() {
  var day = showTwoDigits(this.getDate());
  var month = showTwoDigits(this.getMonth() + 1);
  var year = this.getFullYear();

  return month + "/" + day + "/" + year;
};

// Date.prototype.getInternationalDateString = function(){

//     day = showTwoDigits(this.getDate());
//     month = showTwoDigits(this.getMonth() + 1);
//     year = this.getFullYear();

//     return day+"/"+month+"/"+year;

// }

Date.prototype.minutesAgo = function() {
  var now = new Date();
  var hoursAgo = parseInt(Math.abs(now - this) / (60 * 1000));

  return hoursAgo;
};

Date.prototype.getGoogleCalendarString = function() {
  var date = new Date(this.valueOf());

  var year = date.getFullYear();
  var month = showTwoDigits(date.getMonth() + 1);
  var day = showTwoDigits(date.getDate());

  return year + "-" + month + "-" + day;
};

Date.prototype.getISOString = function() {
  var date = new Date(this.valueOf());

  function pad(n) {
    return n < 10 ? "0" + n : n;
  }

  return (
    date.getUTCFullYear() +
    "-" +
    pad(date.getUTCMonth() + 1) +
    "-" +
    pad(date.getUTCDate()) +
    "T" +
    pad(date.getUTCHours()) +
    ":" +
    pad(date.getUTCMinutes()) +
    ":" +
    pad(date.getUTCSeconds()) +
    "Z"
  );
};

getDateObjectFromString = function(dateString) {
  // if(dateString.substr(2,1) == "/"){
  //     // well'it's in the old format..
  //     var day = dateString.substr(0,2);
  //     var month = dateString.substr(3,2);
  // }
  // else{
  // it's in the new!
  var month = dateString.substr(0, 2);
  var day = dateString.substr(3, 2);
  // }

  var year = dateString.substr(6, 4);

  // datestring must be in international format DD/MM/YYYY
  var date = new Date();

  date.setDate(day);
  date.setMonth(month - 1);
  date.setFullYear(year);

  date.setHours(0, 0, 0, 0);

  return date;
};

getLocaleDateString = function(dateString, hideYear) {
  // #TODO remove the old format check after all dates have been converted to new

  // if(dateString.substr(2,1) == "/"){
  //     // well'it's in the old format..
  //     var day = dateString.substr(0,2);
  //     var month = dateString.substr(3,2);
  // }
  // else{
  // it's in the new!
  var month = dateString.substr(0, 2);
  var day = dateString.substr(3, 2);
  // }

  var slashYear = hideYear ? "" : "/" + dateString.substr(6, 4);

  if (window.settings.dateFormat == "mmdd") {
    return month + "/" + day + slashYear;
  }

  return day + "/" + month + slashYear;
};

getLocaleDateArray = function(dateArray, hideYear) {
  var convertedArray = [];
  var index;
  for (index = 0; index < dateArray.length; ++index) {
    convertedArray.push(getLocaleDateString(dateArray[index], hideYear));
  }
  return convertedArray;
};

// function diffDays(date1, date2){

//     var date1 = new Date(date1);
//     var date2 = new Date(date2);
//     var timeDiff = Math.abs(date2.getTime() - date1.getTime());
//     var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
//     return diffDays;

// }

diffTwoDates = function(date1, date2) {
  let tempDate1 = new Date(date1.getTime());
  let tempDate2 = new Date(date2.getTime());

  tempDate1.setHours(0, 0, 0, 0);
  tempDate2.setHours(0, 0, 0, 0);

  let timeDiff = tempDate2.getTime() - tempDate1.getTime();
  let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return diffDays;
};

diffSeconds = function(date1, date2) {
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  var diffSeconds = Math.ceil(timeDiff / 1000);

  return diffSeconds;
};

minutesToClock = function(minutes) {
  let hours = Math.floor(minutes / 60);
  minutes = showTwoDigits(Math.floor(minutes % 60));
  let seconds = showTwoDigits(0);

  if (hours > 0) return hours + ":" + minutes + ":" + seconds;
  else return minutes + ":" + seconds;
};

secondsToClock = function(seconds) {
  if (typeof seconds == "number") {
    let hours = Math.floor(seconds / 60 / 60);
    let minutes = showTwoDigits(Math.floor((seconds / 60) % 60));
    seconds = showTwoDigits(seconds % 60);

    if (hours > 0) return hours + ":" + minutes + ":" + seconds;
    else return minutes + ":" + seconds;
  }
};

clockToSeconds = function(clock) {
  if (typeof clock == "string") {
    var times = clock.split(":").reverse();

    var seconds = parseInt(times[0]);

    var mins = times[1] || 0;
    var hours = times[2] || 0;

    seconds += hours * 60 * 60;
    seconds += mins * 60;

    return seconds;
  }

  return 0;
};
