<template>
  <transition name="fade-down" appear>
    <div
      class="bg-color-1 color-2 p-20 fixed rounded-md flex bottom-14 z-20 shadow"
    >
      <div class="close-button" @click="close">
        <font-awesome-icon :icon="closeIcon" size="sm"></font-awesome-icon>
      </div>
      <div style="overflow-y:auto;">
        <h3 class="mx-10 my-0" v-html="title"></h3>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: function() {
    return {
      closeTimeoutTracker: null
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    selfTimeout: {
      type: Number,
      default: 3000
    },
    closeIcon: {
      type: String,
      default: "times"
    }
  },
  methods: {
    close: function() {
      // makes the replacement on the notifications array reactive
      vueapp.$set(vueapp.notifications, 0, null);
      setTimeout(() => {
        this.$root.notifications.shift();
      }, 150);
    }
  },
  created: function() {
    this.closeTimeoutTracker = setTimeout(() => {
      this.close();
    }, this.selfTimeout);
  },
  destroyed: function() {
    clearTimeout(this.closeTimeoutTracker);
  }
};
</script>

<style scoped>
.shadow {
  box-shadow: 0px 0px 3px 0px var(--color2);
}

.close-button {
  padding: 1px 4px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.fade-down-enter-active {
  transition: all 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-down-leave-active {
  transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-down-enter {
  transform: translateY(30px);
}

.fade-down-leave-to {
  transform: translateY(-30px);
}

.fade-down-enter,
.fade-down-leave-to {
  opacity: 0;
}
</style>
