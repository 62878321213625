<template>
  <div
    id="app"
    class="h-full w-full flex flex-col items-center"
    :class="{
      'extra-minimal-mode': extraMinimalMode,
      'fast-mode': fastMode,
      'desktop-mode': $root.states.isDesktop
    }"
    :style="$root.themeStyles"
    v-if="!$root.states.isMobile"
  >
    <notifications
      v-if="$root.nextNotification"
      :title="$root.nextNotification.title"
      :close="$root.nextNotification.closeIcon"
      :selfTimeout="$root.nextNotification.selfTimeout"
    ></notifications>
    <!-- <div id="loading" v-cloak v-if="user === undefined"><i class="fas fa-5x fa-circle-notch fa-spin"></i></div> -->
    <home v-if="$root.user === null"></home>
    <div
      v-else-if="$root.currentPanel"
      class="w-full h-full flex bg-color-1 animated"
    >
      <left-menu ref="menu"></left-menu>
      <div id="panels" class="w-full h-full flex flex-col items-center">
        <top-bar @mouseup.native="closeMenu"></top-bar>
        <grid @mouseup.native="closeMenu"></grid>
        <panelSelector @mouseup.native="closeMenu"></panelSelector>
        <confirm-box
          icon="exclamation-circle"
          subtitle="Permanently delete this widget?"
          v-if="$root.states.isDeletingWidgetIndex !== null"
          confirmText="Yes"
          closeText="No"
          @confirm="$root.deleteWidget()"
          @close="$root.states.isDeletingWidgetIndex = null"
        ></confirm-box>
        <confirm-box
          icon="exclamation-circle"
          subtitle="Permanently delete this panel?"
          confirmText="Yes"
          closeText="No"
          v-if="$root.states.isDeletingPanel"
          @confirm="$root.deleteCurrentPanel()"
          @close="$root.states.isDeletingPanel = false"
        ></confirm-box>
        <share v-if="$root.states.isShareShowing"></share>
      </div>
    </div>
    <faq
      v-if="$root.states.isFAQShowing"
      @close="$root.states.isFAQShowing = false"
    ></faq>
    <contact
      v-if="$root.states.isContactShowing"
      @close="$root.states.isContactShowing = false"
    ></contact>
    <confirm-box
      v-if="$root.states.isAccountDeleted"
      title="Your account has been deleted."
      subtitle="Thank you for trying Hero Panel!"
      icon-type="far"
      icon="sad-tear"
      @close="$root.states.isAccountDeleted = false"
    ></confirm-box>
    <div
      v-if="$root.states.isDesktop"
      class="fixed top-0 right-0 pr-10 pt-10 color-2 no-drag"
    >
      <font-awesome-icon
        :icon="['far', 'window-maximize']"
        @click="$root.desktopToggleFullScreen()"
        class="mr-10 cursor-pointer"
      ></font-awesome-icon>
      <font-awesome-icon
        icon="times"
        onclick="appWindow.minimize()"
        class="ml-5 cursor-pointer"
      ></font-awesome-icon>
    </div>

        <!-- Global site tag (gtag.js) - Google Analytics -->
    <script type="application/javascript" defer src="https://www.googletagmanager.com/gtag/js?id=UA-5618502-24"></script>
    <script type="application/javascript">
      console.log("data");
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-5618502-24');
    </script>

  </div>
  <MobileScreen v-else></MobileScreen>
</template>

<script>
import { allWidgets } from "@/js/widgets.js";
import Home from "@/components/home.vue";
import Notifications from "@/components/notifications.vue";
const LeftMenu = () => import("@/components/left-menu/left-menu.vue");
const TopBar = () => import("@/components/top-bar.vue");
const Grid = () => import("@/components/grid.vue");
const PanelSelector = () => import("@/components/panel-selector.vue");
const Share = () => import("@/components/share.vue");
const Faq = () => import("@/components/faq.vue");
const Contact = () => import("@/components/contact.vue");
const ConfirmBox = () => import("@/components/ui/confirm-box.vue");
const MobileScreen = () => import("@/components/mobile-screen.vue");

export default {
  el: "#app",
  data: function() {
    return {};
  },
  components: {
    Home,
    Notifications,
    LeftMenu,
    TopBar,
    Grid,
    PanelSelector,
    Share,
    Faq,
    Contact,
    ConfirmBox,
    MobileScreen
  },
  methods: {
    closeMenu: function() {
      this.$refs.menu.closeAllMenus();
    }
  },
  computed: {
    extraMinimalMode: function() {
      if (this.$root.currentPanel) {
        return this.$root.settings.extraMinimalMode;
      }
      return false;
    },
    fastMode: function() {
      if (this.$root.currentPanel) {
        return this.$root.settings.fastMode;
      }
      return false;
    }
  }
};
</script>
