window.value_limit = function(val, min, max) {
  // returns value within a range
  return val < min ? min : val > max ? max : val;
};

window.getRand = function() {
  return (
    Date.now() +
    Math.random()
      .toString()
      .replace(/0\./g, "-")
  );
};
