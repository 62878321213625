<template>
  <div>
    <div class="tabs">
      <ul>
        <li
          v-for="(tab, index) in tabs"
          v-bind:key="tab.name"
          @click="activeTabIndex = index"
          :class="{ 'is-active': tab.isActive }"
        >
          <span>{{ tab.name }}</span>
        </li>
      </ul>
    </div>

    <div class="tabs-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
module.exports = {
  data() {
    return {
      tabs: [],
      activeTabIndex: 0
    };
  },
  props: {
    selectedTabIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    changeTab: function() {
      this.tabs.forEach((tab, tabIndex) => {
        tab.isActive = this.activeTabIndex == tabIndex;
      });
    }
  },
  mounted() {
    this.tabs = this.$children;
    this.activeTabIndex = this.selectedTabIndex;
    this.changeTab();
  },
  watch: {
    activeTabIndex: function() {
      this.changeTab();
    }
  }
};
</script>

<style scoped>
.tabs {
  padding: 0 20px;
}
.tabs ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.tabs li {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  text-align: center;
}
.tabs-content {
  padding: 20px;
}
.tabs li.is-active {
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6) !important;
}
</style>
