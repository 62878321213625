window.allWidgets = {
  BlankSpace: {
    title: "Blank Space",
    size: { w: 4, h: 4, minW: 1, minH: 1, maxW: 32, maxH: 32 },
    iconStyle: "far",
    icon: "square",
    description: "Just a blank space",
    pro: true,
    desktop: true,
    browser: true
  },
  Books: {
    title: "Books",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 12 },
    iconStyle: "fas",
    icon: "book",
    description: "To-read list",
    pro: false,
    desktop: true,
    browser: true
  },
  Breathing: {
    title: "Breathing",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 4, maxH: 4 },
    iconStyle: "fas",
    icon: "lungs",
    description: "Anti-anxiety breathing",
    pro: true,
    desktop: true,
    browser: true
  },
  BucketList: {
    title: "Bucket List",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 12 },
    iconStyle: "fas",
    icon: "globe-americas",
    description: "Lifetime to-do list",
    pro: false,
    desktop: true,
    browser: true
  },
  Clock: {
    title: "Clock",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 4 },
    iconStyle: "far",
    icon: "clock",
    description: "World's best time tracker",
    pro: false,
    desktop: true,
    browser: true
  },
  CalorieCounter: {
    title: "Calorie Counter",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 4 },
    iconStyle: "fas",
    icon: "utensils",
    description: "Keep track of calories",
    pro: false,
    desktop: true,
    browser: true
  },
  Counter: {
    title: "Counter",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 4 },
    iconStyle: "fas",
    icon: "plus-circle",
    description: "Track count of anything",
    pro: false,
    desktop: true,
    browser: true
  },
  DaysSince: {
    title: "Days Since",
    size: { w: 4, h: 4, minW: 2, minH: 2, maxW: 4, maxH: 4 },
    iconStyle: "far",
    icon: "calendar-plus",
    description: "Daily count since a date",
    pro: true,
    desktop: true,
    browser: true
  },
  DaysUntil: {
    title: "Days until",
    size: { w: 4, h: 4, minW: 2, minH: 2, maxW: 4, maxH: 4 },
    iconStyle: "far",
    icon: "calendar-minus",
    description: "Countdown to a date",
    pro: true,
    desktop: true,
    browser: true
  },
  Games: {
    title: "Games",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 12 },
    iconStyle: "fas",
    icon: "gamepad",
    description: "Track your gaming list",
    pro: false,
    desktop: true,
    browser: true
  },
  Habits: {
    title: "Habits",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 4 },
    iconStyle: "fas",
    icon: "sync-alt",
    description: "Habits that repeat daily",
    pro: true,
    desktop: true,
    browser: true
  },
  Link: {
    title: "Link",
    size: { w: 2, h: 2, minW: 2, minH: 2, maxW: 4, maxH: 4 },
    iconStyle: "fas",
    icon: "link",
    description: "Link to a web URL",
    pro: true,
    desktop: true,
    browser: true
  },
  Movies: {
    title: "Movies",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 12 },
    iconStyle: "fas",
    icon: "film",
    description: "Movies-to-watch list",
    pro: false,
    desktop: true,
    browser: true
  },
  Notes: {
    title: "Notes",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 8, maxH: 8 },
    iconStyle: "far",
    icon: "sticky-note",
    description: "Simple note-taking",
    pro: true,
    desktop: true,
    browser: true
  },
  Quotes: {
    title: "Quotes",
    size: { w: 8, h: 3, minW: 8, minH: 3, maxW: 12, maxH: 4 },
    iconStyle: "fas",
    icon: "quote-right",
    description: "A customizable quote slideshow",
    pro: true,
    desktop: true,
    browser: true
  },
  Tasks: {
    title: "Tasks",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 12 },
    iconStyle: "fas",
    icon: "tasks",
    description: "A simple to-do list",
    pro: false,
    desktop: true,
    browser: true
  },
  Timer: {
    title: "Timer",
    size: { w: 4, h: 4, minW: 2, minH: 2, maxW: 4, maxH: 4 },
    iconStyle: "fas",
    icon: "hourglass-start",
    description: "A time tracker",
    pro: true,
    desktop: true,
    browser: true
  },
  Pomodoro: {
    title: "Pomodoro Timer",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 4 },
    iconStyle: "fas",
    icon: "user-clock",
    description: "A time tracker for the Pomodoro Technique",
    pro: true,
    desktop: true,
    browser: true
  },
  Shortcut: {
    title: "Shortcut",
    size: { w: 2, h: 2, minW: 2, minH: 2, maxW: 4, maxH: 4 },
    iconStyle: "fas",
    icon: "external-link-alt",
    description: "A desktop shortcut to an App or a File",
    pro: true,
    desktop: true,
    browser: false
  },
  Soundboard: {
    title: "Soundboard",
    size: { w: 4, h: 2, minW: 4, minH: 2, maxW: 4, maxH: 2 },
    iconStyle: "fas",
    icon: "tree",
    description: "Relaxing nature sounds",
    pro: true,
    desktop: true,
    browser: true
  },
  Stretching: {
    title: "Stretching",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 4, maxH: 4 },
    iconStyle: "fas",
    icon: "universal-access",
    description: "A stretching routine",
    pro: true,
    desktop: true,
    browser: true
  },
  TheOneThing: {
    title: "The One Thing",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 12, maxH: 4 },
    iconStyle: "fas",
    icon: "exclamation-circle",
    description: "'The One Thing' reminder.\nBased on a book by Gary Keller",
    pro: true,
    desktop: true,
    browser: true
  },
  Title: {
    title: "Title",
    size: { w: 4, h: 2, minW: 4, minH: 2, maxW: 16, maxH: 16 },
    iconStyle: "fas",
    icon: "font",
    description: "A floating title widget",
    pro: false,
    desktop: true,
    browser: true
  },
  Workout: {
    title: "Workout",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 4, maxH: 4 },
    iconStyle: "fas",
    icon: "walking",
    description: "A quick workout routine",
    pro: true,
    desktop: true,
    browser: true
  },
  Yoga: {
    title: "Yoga",
    size: { w: 4, h: 4, minW: 4, minH: 4, maxW: 4, maxH: 4 },
    iconStyle: "fas",
    icon: "praying-hands",
    description: "A simple yoga routine",
    pro: true,
    desktop: true,
    browser: true
  }

  // wallpaper:          { w: 2, h: 1, minW: 1, minH: 1, maxW: 3, maxH: 2, icon: "" },
  // 'calorie-counter':  { w: 1, h: 1, minW: 1, minH: 1, maxW: 2, maxH: 2, icon: "" },
  // 'nutrient-counter': { w: 1, h: 1, minW: 1, minH: 1, maxW: 1, maxH: 2, icon: "" },
  // 'sleep-counter':    { w: 1, h: 1, minW: 1, minH: 1, maxW: 2, maxH: 2, icon: "" },
};
