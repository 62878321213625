//Running a browser application (Offline using local storage)
import { debounce } from "./utils.dko.js";

window.browserSaver = {
  id: "browser",
  initialized: false,
  initialize: function() {},

  configSaver: {
    readData: function() {
      // Retrieve the object from storage
      console.log("Reading config data");
      var retrievedObject = localStorage.getItem("settings");
      try {
        if (retrievedObject) {
          return JSON.parse(retrievedObject);
        }
      } catch (error) {
        console.log(error);
      }
    },
    saveData: function(settingsObject) {
      console.log("Saving config data");
      debounce(
        () => {
          localStorage.setItem("settings", JSON.stringify(settingsObject));
        },
        "settings",
        1000 // lower threshold because it's just saving to localStorage
      )();
    }
  },

  widgetSaver: {
    readData: function(id) {
      // Retrieve the object from storage
      console.log("Reading widget data: " + id);
      var retrievedObject = localStorage.getItem(id);
      try {
        if (retrievedObject) {
          return JSON.parse(retrievedObject);
        }
      } catch (error) {
        console.log(error);
      }
    },
    saveData: function(widget_object) {
      debounce(
        () => {
          console.log("Saving widget: " + widget_object.id);
          localStorage.setItem(
            widget_object.id,
            JSON.stringify(widget_object.persistent)
          );
        },
        widget_object.id,
        1000 // lower threshold because it's just saving to localStorage
      )();
    },
    deleteData: function(id) {
      localStorage.removeItem(id);
    }
  },

  panelSaver: {
    readData: function(id) {
      // Retrieve the object from storage
      console.log("Reading panel data: " + id);
      var retrievedObject = localStorage.getItem(id);
      try {
        if (retrievedObject) {
          return JSON.parse(retrievedObject);
        }
      } catch (error) {
        console.log(error);
      }
    },
    saveData: function(panel_object) {
      debounce(
        () => {
          console.log("Saving panel: " + panel_object.id);
          localStorage.setItem(panel_object.id, JSON.stringify(panel_object));
        },
        panel_object.id,
        1000 // lower threshold because it's just saving to localStorage
      )();
    },
    deleteData: function(id) {
      localStorage.removeItem(id);
    }
  },

  panelsSaver: {
    readData: function() {
      console.log("Reading panels data");
      var retrievedObject = localStorage.getItem("panels");
      try {
        return JSON.parse(retrievedObject);
      } catch (error) {
        return null;
      }
      // return JSON.parse(localStorage.getItem("panels"));
    },
    saveData: function(data) {
      debounce(
        () => {
          console.log("Saving panels data");
          localStorage.setItem("panels", JSON.stringify(data));
        },
        "panels",
        1000 // lower threshold because it's just saving to localStorage
      )();
    }
  },

  importerExporter: {
    exportData: function() {
      let now = Date.now();
      let dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(localStorage));
      let downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "heropanel-" + now + ".hero");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },

    resetData: function() {
      localStorage.clear();
      vueapp.loadPanel();
    },

    importData: function() {
      // Create the reader element
      let uploadInputNode = document.createElement("input");
      uploadInputNode.setAttribute("type", "file");
      uploadInputNode.setAttribute("accept", ".hero");

      // Listen for a change (file selected)
      uploadInputNode.addEventListener(
        "change",
        function() {
          // Read the file and set it on localStorage
          let file = this.files[0];
          let fileReader = new FileReader();

          fileReader.onload = function(fileLoadedEvent) {
            let textFromFileLoaded = fileLoadedEvent.target.result;
            let jsonObject = JSON.parse(textFromFileLoaded);

            localStorage.clear();

            Object.keys(jsonObject).forEach(function(key) {
              localStorage.setItem(key, jsonObject[key]);
            });

            vueapp.loadPanel();
          };

          fileReader.readAsText(file);
        },
        false
      );

      document.body.appendChild(uploadInputNode); // required for firefox
      uploadInputNode.click();
      uploadInputNode.remove();
    }
  }
};
