import Vue from "vue";
import App from "./App.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircle,
  faSadTear,
  faArrowLeft,
  faEnvelope,
  faKey,
  faCheckSquare,
  faSquare,
  faPlus,
  faUnlock,
  faSpinner,
  faLink,
  faLock,
  faTimes,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faStar,
  faQuestionCircle,
  faSignOutAlt,
  faUserSlash,
  faToggleOn,
  faArrowRight,
  faStopwatch,
  faList,
  faCheck,
  faBook,
  faCarrot,
  faChartLine,
  faPiggyBank,
  faCaretUp,
  faCaretDown,
  faBookOpen,
  faGlobeAmericas,
  faUndoAlt,
  faMinus,
  faGamepad,
  faFilm,
  faVolumeDown,
  faVolumeMute,
  faStepBackward,
  faPlay,
  faPause,
  faStepForward,
  faPencilAlt,
  faExpand,
  faForward,
  faThumbtack,
  faChevronDown,
  faChevronRight,
  faCloudRain,
  faUmbrellaBeach,
  faFireAlt,
  faWater,
  faWind,
  faFeatherAlt,
  faLungs,
  faUtensils,
  faPlusCircle,
  faSyncAlt,
  faQuoteRight,
  faTasks,
  faHourglassStart,
  faUserClock,
  faTree,
  faUniversalAccess,
  faExclamationCircle,
  faFont,
  faWalking,
  faPrayingHands,
  faExternalLinkAlt,
  faDesktop
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircle as farCircle,
  faPlusSquare as farPlusSquare,
  faEye as farEye,
  faUserCircle as farUserCircle,
  faStar as farStar,
  faSquare as farSquare,
  faHandPaper as farHandPaper,
  faCheckSquare as farCheckSquare,
  faClock as farClock,
  faCalendarPlus as farCalendarPlus,
  faCalendarMinus as farCalendarMinus,
  faStickyNote as farStickyNote,
  faWindowMaximize as farWindowMaximize,
  faImage as farImage
} from "@fortawesome/free-regular-svg-icons";
import {
  faGoogle as fabGoogle,
  faFacebookSquare as fabFacebookSquare,
  faTwitterSquare as fabTwitterSquare,
  faPinterestSquare as fabPinterestSquare,
  faLinkedin as fabLinkedin,
  faProductHunt as fabProductHunt
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faCircle,
  faSadTear,
  faArrowLeft,
  faEnvelope,
  faKey,
  faCheckSquare,
  faSquare,
  faPlus,
  faUnlock,
  faSpinner,
  faLock,
  faLink,
  faTimes,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faStar,
  faQuestionCircle,
  faSignOutAlt,
  faUserSlash,
  faToggleOn,
  faArrowRight,
  faStopwatch,
  faList,
  faCheck,
  faBook,
  faCarrot,
  faChartLine,
  faPiggyBank,
  faCaretUp,
  faCaretDown,
  faBookOpen,
  faGlobeAmericas,
  faUndoAlt,
  faMinus,
  faGamepad,
  faFilm,
  faVolumeDown,
  faVolumeMute,
  faStepBackward,
  faPlay,
  faPause,
  faStepForward,
  faPencilAlt,
  faExpand,
  faForward,
  faThumbtack,
  faChevronDown,
  faChevronRight,
  faCloudRain,
  faUmbrellaBeach,
  faFireAlt,
  faWater,
  faWind,
  faFeatherAlt,
  faLungs,
  faUtensils,
  faPlusCircle,
  faSyncAlt,
  faQuoteRight,
  faTasks,
  faHourglassStart,
  faUserClock,
  faTree,
  faUniversalAccess,
  faExclamationCircle,
  faFont,
  faWalking,
  faPrayingHands,
  faExternalLinkAlt,
  faDesktop
);
library.add(
  farCircle,
  farPlusSquare,
  farEye,
  farUserCircle,
  farStar,
  farSquare,
  farHandPaper,
  farCheckSquare,
  farClock,
  farCalendarPlus,
  farCalendarMinus,
  farStickyNote,
  farWindowMaximize,
  farImage
);
library.add(
  fabGoogle,
  fabFacebookSquare,
  fabTwitterSquare,
  fabPinterestSquare,
  fabLinkedin,
  fabProductHunt
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

window.isDesktop =
  window && window.process && window.process.type ? true : false;

window.isMobile =
  /mobile/i.test(navigator.userAgent) &&
  !/ipad|tablet/i.test(navigator.userAgent);

if (isDesktop) {
  window.fs = window.require("fs");
  window.path = window.require("path");
  window.remote = window.require("electron").remote;
  window.shell = window.require("electron").shell;
  window.appWindow = window.remote.getCurrentWindow();
}

// css imports
import "@/css/roboto.css";
import "@/css/tailwind.dko.css";
import "@/css/style.css";
import "@/css/animations.css";
import "@/css/tooltip.css";
import "@/css/slideshow.dko.css";

// js imports
import "@/js/math.dko.js";
import "@/js/saver-browser.js";
import "@/js/widgets.js";
import "@/js/date.dko.js";
import "@/js/utils.dko.js";
// import "@js/array.dko.js";
// import "@js/saver-desktop.js";
// import "@js/saver-firebase.js";

import { firebase } from "@firebase/app";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyAT0KAPvX3iOAs4Q2-DTxcVZ2c1rKUT2pg",
  authDomain: "hero-panel.firebaseapp.com",
  databaseURL: "https://hero-panel.firebaseio.com",
  projectId: "hero-panel",
  storageBucket: "hero-panel.appspot.com",
  messagingSenderId: "63274585294",
  appId: "1:63274585294:web:42ca5277de1b3bf9eb949f",
  measurementId: "G-24Y461RPZ1"
};

if (window.isDesktop) {
  firebaseConfig.apiKey = remote.getGlobal("apiKey");
}

window.firebase = firebase;
window.firebaseApp = firebase.initializeApp(firebaseConfig);

// window.openLink = function(link) {
//   if (isDesktop && shell) {
//     shell.openExternal(link);
//   } else {
//     window.open(link, "_blank");
//   }
// };

window.vueapp = new Vue({
  data: {
    route: window.location.hash,
    user: undefined,
    saver: null,
    date: new Date(),
    day: new Date().getDate(),
    notifications: [],
    states: {
      isDesktop: window.isDesktop,
      isMobile: window.isMobile,
      isDeletingWidgetIndex: null,
      isDeletingPanel: false,
      isShareShowing: false,
      isFAQShowing: false,
      isContactShowing: false,
      isPanelWatched: true,
      isPanelsListWatched: false,
      isConfigWatched: false,
      isAccountDeleted: false,
      isNotificationShowing: true
    },
    config: {
      currentPanelIndex: 0,
      language: null,
      dateFormat: null,
      proLocked: true
    },
    allWidgets: window.allWidgets,
    panels: [],
    currentPanel: null
  },
  methods: {
    notify: function(title, collapseQueue, selfTimeout, closeIcon) {
      if (collapseQueue) {
        this.notifications = [];
      }
      this.$nextTick(function() {
        // Code that will run only after the
        // entire view has been rendered

        this.notifications.push({
          title: title,
          selfTimeout: selfTimeout,
          collapseQueue: collapseQueue,
          closeIcon: closeIcon
        });
      });
    },
    openLink: function(link) {
      if (link) {
        if (this.states.isDesktop) {
          window.shell.openExternal(link);
        } else {
          window.open(link, "_blank");
        }
      }
    },
    selectSaver: function() {
      // if (this.onlineMode) {
      //   console.log("cloud saver selected");
      //   return cloudSaver;
      // } else {
      //   if (isDesktop) {
      //     console.log("desktop saver selected");
      //     return desktopSaver;
      //   } else {
      return window.browserSaver;
      // }
      // }
    },
    addWidget: function(slug, index) {
      let widgetObject = {
        id: slug + "-" + window.getRand(),
        slug: slug,
        rowSpan: this.allWidgets[slug].size.h,
        colSpan: this.allWidgets[slug].size.w
      };
      this.gridWidgets.splice(index, 0, widgetObject);
    },
    deleteWidget: function() {
      let index = this.states.isDeletingWidgetIndex;
      let widget = this.gridWidgets[index];
      this.saver.widgetSaver.deleteData(widget.id);
      this.gridWidgets.splice(index, 1);
      this.states.isDeletingWidgetIndex = null;
    },
    savePanelUnwatched: function() {
      this.states.isPanelWatched = false;
    },
    newPanel: function() {
      console.log("creating new panel");
      this.states.isPanelsListWatched = true;

      let settings = {
        extraMinimalMode: false,
        fastMode: false,
        freeMode: false,
        roundEdges: false,
        editLocked: false,
        transparency: 1,
        widgetSize: 40,
        gridPadding: 20,
        color1: "#DDD",
        color2: "#222",
        color3: "#DDD"
      };

      if (this.currentPanel) {
        settings = this.currentPanel.settings;
      }

      let panel = {
        id: "panel-" + window.getRand(),
        settings: settings,
        widgets: []
      };

      this.panels.push(panel.id);
      this.currentPanel = panel;
      this.currentPanelIndex = this.panels.length - 1;
    },
    togglePanelEdit: function() {
      this.settings.editLocked = !this.settings.editLocked;
      if (this.settings.editLocked) {
        this.notify("Panel Editing Locked", true);
      } else {
        this.notify("Panel Editing Unlocked", true);
      }
    },
    loadPanel: function(index) {
      console.log("loading panel " + index);
      if (index === undefined) {
        index = 0;
      }

      let panelID = this.panels[index];
      let currentPanel = this.saver.panelSaver.readData(panelID);

      console.log(currentPanel);

      if (currentPanel) {
        this.states.isPanelWatched = false;
        this.currentPanel = currentPanel;
        this.updateOpacity();
      }
    },
    loadPanels: function() {
      console.log("loading panels");
      if (this.saver.id == "firebase") {
        // Firebase driver loads and syncs automatically
        this.saver.panelsSaver.readData();
      } else {
        // Gotta load manually
        let panels = this.saver.panelsSaver.readData();
        if (panels) {
          this.panels = panels;
          this.loadPanel();
        } else {
          // nothing saved, let's create
          this.newPanel();
        }
      }
    },
    loadConfigs: function() {
      console.log("loading configs");
      let configs = this.saver.configSaver.readData();
      if (configs) {
        this.config = configs; // First Load
      } else {
        this.states.isConfigWatched = true;
      }
    },
    deleteCurrentPanel: function() {
      this.saver.panelSaver.deleteData(this.currentPanel.id);
      this.panels.splice(this.currentPanelIndex--, 1);
      this.states.isDeletingPanel = false;
    },
    resetAll: function() {
      window.localStorage.clear();
    },
    signOut: function() {
      window.firebaseApp.auth().signOut();
    },
    authenticate: function() {
      window.firebaseApp.auth().onAuthStateChanged(user => {
        if (user) {
          this.user = user;
          // if (this.saver.id == "cloud") {
          //   console.log("Cloud driver selected: Setting up firestore");
          //   db = firebase
          //     .firestore()
          //     .collection("users")
          //     .doc(user.email);
          // }
        } else {
          this.user = null;
        }
      });
    },
    deleteAccount: function() {
      this.user
        .delete()
        .then(() => {
          // this.$root.user = "Wait";
          this.resetAll();
          this.states.isAccountDeleted = true;
        })
        .catch(error => {
          alert(error.message);
          // this.errorMessage = "Something went wrong. Please contact us.";
          // An error happened.
        });
    },
    addKeyboardShortcuts: function() {
      window.addEventListener("keyup", e => {
        let activeEl = document.activeElement;
        if (
          !(
            ["INPUT", "TEXTAREA"].includes(activeEl.tagName) ||
            activeEl.hasAttribute("contentEditable")
          )
        ) {
          switch (e.keyCode) {
            case 37:
              this.currentPanelIndex--;
              break;
            case 39:
              this.currentPanelIndex++;
              break;
            default:
              break;
          }
        }
      });
    },
    updateOpacity: function() {
      if (this.states.isDesktop)
        window.appWindow.setOpacity(Number(this.settings.transparency));
    },
    desktopToggleFullScreen: function() {
      if (this.states.isDesktop) {
        // We set up a custom flag on the appWindow object because there is currently a
        // bug in Electron where isFullScreen always returns false, tracked here: https://github.com/electron/electron/issues/20969
        if (appWindow.customFullScreenFlag) {
          appWindow.setFullScreen(false);
          appWindow.customFullScreenFlag = false;
        } else {
          appWindow.setFullScreen(true);
          appWindow.customFullScreenFlag = true;
        }
      }
    }
  },
  computed: {
    nextNotification: function() {
      return this.notifications[0];
    },
    themeStyles: function() {
      if (this.currentPanel)
        return {
          "--color1": this.currentPanel.settings.color1,
          "--color2": this.currentPanel.settings.color2,
          "--color3": this.currentPanel.settings.color3
        };
      return "";
    },
    onlineMode: function() {
      return false; // forcing browser mode
      // return this.route != "#offline";
    },
    settings: function() {
      return this.currentPanel.settings;
    },
    gridWidgets: function() {
      return this.currentPanel.widgets;
    },
    isGridEmpty: function() {
      return !this.currentPanel.widgets.length;
    },
    currentPanelIndex: {
      get: function() {
        return this.config.currentPanelIndex;
      },
      set: function(value) {
        this.config.currentPanelIndex = value;
      }
    },
    currentPanelLocked: {
      get: function() {
        return this.settings.editLocked;
      },
      set: function(value) {
        if (this.settings.editLocked != value) {
          this.settings.editLocked = value;
          if (this.settings.editLocked) {
            this.notify("Panel Editing Locked", true);
          } else {
            this.notify("Panel Editing Unlocked", true);
          }
        }
      }
    },
    panelOpacity: {
      get: function() {
        return this.settings.transparency;
      },
      set: function(value) {
        this.settings.transparency = value;
        this.updateOpacity();
      }
    }
  },
  watch: {
    panels: function(data) {
      if (this.states.isPanelsListWatched) {
        this.saver.panelsSaver.saveData(data);
      }
      this.states.isPanelsListWatched = true;
    },
    user: function() {
      if (this.user != null) {
        this.loadConfigs();
        this.loadPanels();
      }
    },
    config: {
      handler(data) {
        if (this.states.isConfigWatched) {
          this.saver.configSaver.saveData(data);
        }
        this.states.isConfigWatched = true;
      },
      deep: true
    },
    currentPanelIndex: function(index) {
      this.currentPanelIndex = Math.abs(
        (this.panels.length + index) % this.panels.length
      );
      this.loadPanel(this.currentPanelIndex);
    },
    currentPanel: {
      handler(data) {
        if (this.states.isPanelWatched) {
          this.saver.panelSaver.saveData(data);
        }
        this.states.isPanelWatched = true;
      },
      deep: true
    }
  },
  mounted() {
    // this.states.isDesktop = window.isDesktop;

    if (!this.states.isMobile) {
      setInterval(() => {
        let d = new Date();
        this.date = d;
        this.day = d.getDate();
      }, 60 * 1000);

      this.saver = this.selectSaver();
      this.authenticate();
      this.addKeyboardShortcuts();
    }
  },
  render: h => h(App)
}).$mount("#app");

// Register a global custom directive called `v-focus`
Vue.directive("focus", {
  inserted: function(el) {
    el.focus({ preventScroll: true });
  }
});
