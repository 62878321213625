<template>
  <div class="bg-color-1 color-2 w-full h-full">
    <div id="home-container">
      <div
        id="home-head"
        class="w-full mt-10 flex justify-between items-center drag"
      >
        <div style="height: 55px">
          <div class="p-5 animated flex flex-row h-full">
            <logo></logo>
          </div>
        </div>
        <div class="no-drag">
          <span
            @click="states.selectedLoginTab = 0"
            class="p-10 mr-20 cursor-pointer"
            >Sign In</span
          >
          <button
            @click="states.selectedLoginTab = 1"
            class="border-2 rounded-md"
          >
            Create Account
          </button>
        </div>
      </div>
      <div class="flex w-full justify-between select-none">
        <div class="py-10 px-20">
          <h1 class="text-5xl m-0">The Dashboard<br />for Super Humans</h1>
          <p class="text-3xl">
            A digital toolset<br />to improve
            <transition mode="out-in" name="fade"
              ><span class="font-bold" :key="rotated">{{
                rotated
              }}</span></transition
            >
          </p>
          <button
            @click="states.selectedLoginTab = 1"
            class="text-lg my-20 px-20 inverted border-2 rounded-md"
          >
            Create your free panel
          </button>
          <!-- <p>Care about your employees? <a target="_blank" href="https://forms.gle/Hg6NHNPGuGN5iUaJ6">Let's talk business.</a></p> -->
        </div>
        <div class="relative">
          <!-- <div id="featured-on-product-hunt" class="flex justify-center absolute -top-20 right-0 border rounded-sm">
            <a href="https://www.producthunt.com/posts/hero-panel-2-0/maker-invite?code=QM1UjN" target="_blank" class="no-underline flex items-center mr-10">
              <div class="m-10 font-bold text-center">
                Featured on<br />
                Product Hunt!
              </div>
              <font-awesome-icon :icon="['fab', 'product-hunt']" size="2x" style="color:#DA552F"></font-awesome-icon>
            </a>
          </div> -->
          <monitor-outline></monitor-outline>
        </div>
      </div>

      <div class="w-full flex justify-between px-20 py-10">
        <div>
          <span class="pr-10">Hero Panel™ 2017</span>-<a
            href="#"
            @click="$root.states.isFAQShowing = true"
            class="px-10"
            >FAQ</a
          >|<a
            href="#"
            class="px-10"
            @click="$root.states.isContactShowing = true"
            >Contact</a
          >
        </div>
        <div>Built with ♥ in Brasil</div>
      </div>
      <login-form
        v-if="states.selectedLoginTab !== null"
        :selected-tab-index="states.selectedLoginTab"
        @close="states.selectedLoginTab = null"
      ></login-form>
    </div>
  </div>
</template>

<script>
// import Notifications from "@/components/notifications.vue";
import LoginForm from "@/components/login-form.vue";
import MonitorOutline from "@/components/svg/monitor-outline.vue";
import Logo from "@/components/svg/logo.vue";

export default {
  components: {
    // Notifications,
    LoginForm,
    MonitorOutline,
    Logo
  },
  data: function() {
    return {
      states: {
        isContactshowing: false,
        isFAQshowing: false,
        selectedLoginTab: null
      },
      rotatorIndex: 0,
      rotator: [
        "your work.",
        "your health.",
        "your mood.",
        "your mind.",
        "your life."
      ]
    };
  },
  methods: {},
  computed: {
    rotated: function() {
      return this.rotator[Math.abs(this.rotatorIndex) % this.rotator.length];
    }
  },
  created: function() {
    setInterval(() => {
      this.rotatorIndex++;
    }, 3200);

    this.$root.notify("Hero Panel 2.0 is now live!", false, 6000);
    this.$root.notify("Click on FAQ to view version 2.0 changes", false, 6000);
  }
};
</script>

<style scoped>
#home-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  align-content: space-between;
  max-width: 1050px;
  padding: 20px;
  height: 100%;
  margin: auto;
  box-sizing: border-box;
}
</style>
