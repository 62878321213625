<template>
  <transition name="fade" appear>
    <div class="modal-background" @mousedown.self="dismiss()">
      <div class="bg-color-1 color-2 p-20 relative rounded-sm flex">
        <div
          v-if="showCloseButton"
          class="close-button"
          @click="$emit('close')"
        >
          <font-awesome-icon icon="times"></font-awesome-icon>
        </div>
        <div style="overflow-y:auto;">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    dismissable: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    dismiss: function() {
      if (this.dismissable) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 30;
  padding: 30px;
}

.modal-background > div {
  max-width: 600px;
  max-height: 80vh;
  /* overflow-y:auto; */
}

.close-button {
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
</style>
