<template>
  <modal :show-close-button="false" @close="$emit('close')">
    <div>
      <div class="px-20 mb-20 w-full box-border" style="height: 50px">
        <logo></logo>
      </div>
      <tabs :selected-tab-index="selectedTabIndex">
        <tab name="Login">
          <p class="text-center mt-0">Log in to your account</p>
          <form @submit.prevent="signIn" class="flex flex-col">
            <div class="icon-input-container">
              <font-awesome-icon icon="envelope"></font-awesome-icon>
              <input
                class="my-5 color-2"
                required
                type="email"
                v-model="email"
                placeholder="E-mail"
                name="email"
              />
            </div>
            <div class="icon-input-container">
              <font-awesome-icon icon="key"></font-awesome-icon>
              <input
                class="my-5 color-2"
                required
                type="password"
                v-model="password"
                placeholder="Password"
                name="password"
              />
            </div>
            <button
              class="mt-20"
              type="submit"
              v-bind:disabled="!email || !password"
            >
              Login
            </button>
            <small class="text-center error-msg mt-10">{{ error }}</small>
            <small
              class="text-center mt-10 cursor-pointer"
              @click="states.isResettingPassword = true"
              >Forgot your Password?</small
            >
          </form>
        </tab>
        <tab name="Register">
          <p class="text-center mt-0">Create a New Account</p>
          <form @submit.prevent="createAccount" class="flex flex-col">
            <div class="icon-input-container">
              <font-awesome-icon icon="envelope"></font-awesome-icon>
              <input
                class="my-5 color-2"
                required
                type="email"
                v-model="email"
                placeholder="E-mail"
                name="email"
              />
            </div>
            <div class="icon-input-container">
              <font-awesome-icon icon="key"></font-awesome-icon>
              <input
                class="my-5 color-2"
                required
                type="password"
                v-model="password"
                placeholder="Password"
                name="password"
              />
            </div>
            <button
              class="mt-10"
              type="submit"
              :disabled="!email || !password || !states.isTOSaccepted"
            >
              Register
            </button>
            <input type="checkbox" id="agree" v-model="states.isTOSaccepted" />
            <label for="agree" class="mt-20 cursor-pointer">
              <font-awesome-icon
                class="align-middle"
                size="lg"
                icon="check-square"
                v-if="states.isTOSaccepted"
              ></font-awesome-icon>
              <font-awesome-icon
                class="align-middle"
                size="lg"
                icon="square"
                v-else
              ></font-awesome-icon>
              <small class="text-center ml-5 select-none"
                >I agree to the
                <span
                  class="underline"
                  @click.prevent="states.isTosShowing = true"
                  >Terms of Service</span
                >
                and
                <span
                  class="underline"
                  @click.prevent="states.isPrivacyPolicyShowing = true"
                  >Privacy Policy</span
                ></small
              >
            </label>
          </form>
        </tab>
      </tabs>
      <div v-if="!$root.states.isDesktop" class="px-20 pb-10">
        <button
          class="w-full inverted"
          type="button"
          @click="googleSignIn"
          :disabled="!states.isTOSaccepted"
        >
          Sign in with Google
          <font-awesome-icon
            :icon="['fab', 'google']"
            class="ml-5"
          ></font-awesome-icon>
        </button>
      </div>
      <resetPassword
        v-if="states.isResettingPassword"
        @close="states.isResettingPassword = false"
      ></resetPassword>
      <privacy-policy
        v-if="states.isPrivacyPolicyShowing"
        @close="states.isPrivacyPolicyShowing = false"
      ></privacy-policy>
      <terms-of-service
        v-if="states.isTosShowing"
        @close="states.isTosShowing = false"
      ></terms-of-service>
    </div>
  </modal>
</template>

<script>
const PrivacyPolicy = () => import("@/components/privacy-policy.vue");
const TermsOfService = () => import("@/components/terms-of-service.vue");
const ResetPassword = () => import("@/components/reset-password.vue");
import Tabs from "@/components/ui/tabs.vue";
import Tab from "@/components/ui/tab.vue";
import Logo from "@/components/svg/logo.vue";
import Modal from "@/components/ui/modal.vue";

export default {
  components: {
    PrivacyPolicy,
    TermsOfService,
    ResetPassword,
    Tabs,
    Tab,
    Logo,
    Modal
  },
  props: {
    selectedTabIndex: {
      type: Number,
      default: 0
    }
  },
  data: function() {
    return {
      states: {
        isPrivacyPolicyShowing: false,
        isTosShowing: false,
        isResettingPassword: false,
        isTOSaccepted: true,
        isErrorShowing: false,
        isButtonsDisabled: false
      },

      email: null,
      password: null,
      error: ""
    };
  },
  methods: {
    signIn: function() {
      window.firebaseApp
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .catch(error => {
          console.log(error);
          if (error.code === "auth/wrong-password") {
            this.error = "Wrong Password";
            this.states.isForgotPasswordShowing = true;
          } else {
            this.error = error.message;
          }
        });
    },
    googleSignIn: function() {
      window.firebaseApp
        .auth()
        .signInWithPopup(new window.firebase.auth.GoogleAuthProvider())
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          console.log(error);
        });
    },
    signOut: function() {
      window.firebaseApp.auth().signOut();
    },
    createAccount: function() {
      window.firebaseApp
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password) // "raphil@arkade.com.br", "passiwordi"
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          if (error.code == "auth/email-already-in-use") {
            console.log("in use");
            this.signIn(this.email, this.password);
          }
        });
    },
    resetPassword: function() {
      window.firebaseApp
        .auth()
        .sendPasswordResetEmail("raphadko@gmail.com", {
          url: "http://localhost:5000"
        })
        .then(function(result) {
          console.log(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
::placeholder {
  color: var(--color2);
}

#login-form {
  padding: 10px 0 20px 0;
  width: 300px;
  /* margin: 10px 20px; */
}

.icon-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.icon-input-container svg {
  position: absolute;
  left: 10px;
  opacity: 0.6;
}

.icon-input-container input {
  padding-left: 38px;
  width: 100%;
}
</style>
