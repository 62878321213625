// This assigns UID to debounce timers so that they do not override
window.debounceTracker = {};

export function debounce(fn, uid, delay) {
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(window.debounceTracker[uid]);
    window.debounceTracker[uid] = setTimeout(() => {
      delete window.debounceTracker[uid];
      fn.apply(context, args);
    }, delay);
  };
}

// Gets a nested object providing the path in an array
const getNestedObject = (nestedObj, pathArr) => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    nestedObj
  );
};

export function isImageValid(url) {
  let img = new Image();

  img.onload = () => {
    console.log("valide");
    // callback(true);
  };
  img.onerror = () => {
    console.log("invalide");
    // callback(false);
  };
  img.src = url;
}

// Sample usage
// var imageUrl = "http://www.google.com/images/srpr/nav_logo14.png";
// imageExists(imageUrl, function(exists) {
//   console.log("RESULT: url=" + imageUrl + ", exists=" + exists);
// });

// Extends object to implement a foreach method
// if (!Object.prototype.forEach) {
//   Object.defineProperty(Object.prototype, "forEach", {
//     value: function (callback, thisArg) {
//       if (this == null) {
//         throw new TypeError("Not an object");
//       }
//       thisArg = thisArg || window;
//       for (var key in this) {
//         if (this.hasOwnProperty(key)) {
//           callback.call(thisArg, this[key], key, this);
//         }
//       }
//     },
//   });
// }

// Returns an array tree until a number is found, used to find objects until there is an array inside
// where the number it findes does not represent an object property but an array index
function arrayUntilNumber(a) {
  for (let i = 0; i < a.length; i++) {
    if (typeof a[i] === "number") {
      a.splice(i, a.length - i);
      break;
    }
  }
  return a;
}

Object.filter = function(obj, predicate) {
  let result = {},
    key;
  for (key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && predicate(obj[key])) {
      result[key] = obj[key];
    }
  }
  return result;
};

function numberOnly(element) {
  element.value = element.value.replace(/\D/g, "");
}
